import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import { IoPricetag, IoTime } from "react-icons/io5";
import Seo from "../components/seo";

const Delivery = () => {
    return (
        <Layout>
            <Seo
                title="Entrega - BIKESAFE"
                description="At Bike Safe we will always endeavor to get your order to you on time, however if the item is out of stock for a prolonged period, one of our customer service reps will contact you via e-mail or phone to update you on the progress of your order."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5">
                        Envío gratuito de todos los pedidos
                        </h1>
                        <p className="flow-text">En bikesafe.es siempre nos esforzaremos para entregarle su pedido a tiempo, pero si el producto está agotado durante un período prolongado, uno de nuestros representantes de atención al cliente se pondrá en contacto con usted, a través de correo electrónico o por teléfono, para mantenerle informado del estado de su pedido.</p>
                        <h2 className="mt-5 mb-5"><IoPricetag /> ¿Cuánto costará?</h2>
                        <p className="flow-text">
                        La entrega es gratuita en el Peninsular de España. Un pequeño suplemento se aplicará a las Islas Baleares y Canarias.</p>
                        <h2 className="mt-5 mb-5"><IoTime /> ¿Cuánto tardará?</h2>
                        <p className="flow-text">Los plazos de entrega varían dependiendo del producto. Los plazos de entrega aproximados se muestran con cada producto siempre que sea posible, pero no se pueden confirmar los plazos de entrega exactos.</p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Delivery;